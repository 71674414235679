import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import 'swiper/css/swiper.css'

import { goodsSkuInfo } from "@/api/goods/goods"
import { getToken } from "@/utils/auth"
import { addToCart, editCartNum } from "@/api/goods/cart"

import { goodsGetSizeGuied } from "@/api/pc"

export default {
    name: "bagShop",
    components: {
        Swiper,
        SwiperSlide
    },
    data: () => {
        return {
            bagTitle: "ADD TO BAG",
            // num: 1,
            images: [
                // require('@/assets/images/goods/bigPicture.png'),
                // require('@/assets/images/goods/bigPicture.png'),
                // require('@/assets/images/goods/bigPicture.png'),
                // require('@/assets/images/goods/bigPicture.png'),
                // require('@/assets/images/goods/bigPicture.png'),
                // require('@/assets/images/goods/bigPicture.png'),
                // require('@/assets/images/goods/bigPicture.png'),
            ],
            swiperOptionTop: {
                loop: true,
                loopedSlides: 7,
                // spaceBetween: 10,   //间距
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },
                direction: "vertical"
            },
            swiperOptionThumbs: {
                loop: true,
                loopedSlides: 7,
                slidesPerView: "auto",
                touchRatio: 0.2,
                slideToClickedSlide: true,
                direction: "vertical"
            },
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
            countrySoyego: localStorage.getItem('countrySoyego'),
            toBag: {
                sku_id: 0,
                site_id: 0,
                cart_id: 0
            },
            productDetail: {
                goods_name: '',
                market_price: 0,
                discount_price: 0,
                save: 0,
                sku_no: '',
                colorList: [],
                colorAtive: 0,
                colorAtiveName: '',
                sizeData: Object,
                sizeAtive: 0,
                sizeAtiveName: '',
                delivery_money: 0,
                num: 0,
                imgUrl: '',
                sku_id: 0,
                site_id: 0,
                verify_state: 0,
                cart_id: 0
            },
            loading: true,
            navBannerAtive: 0,
            transformX: 0,
            colorIndex: 0,
            sizeIndex: 0
        }
    },
    props: ["ids", "num", "cart", "cartId"],
    watch: {
        '$route': 'fetchData',
        ids: {
            deep: true,
            handler: function (val) {
                this.ids = val;
                this.fetchData();
            },
        },
        num: {
            deep: true,
            handler: function (val) {
                this.num = val;
                this.productDetail.num = val;
                // this.fetchData();
            },
        },
        cartId: {
            deep: true,
            handler: function (val) {
                this.cartId = val;
                this.fetchData();
            },
        },
        // cart: {
        //     deep: true,
        //     handler: function (val) {
        //         if(val == true) {
        //             this.bagTitle = "UPDATE";
        //         }else{
        //             this.bagTitle = "ADD TO BAG";
        //         }
        //         this.cart = val;
        //         this.fetchData();
        //     },
        // },
    },
    created() {
        if(this.cart == true){
            this.bagTitle = "UPDATE";
        }else{
            this.bagTitle = "ADD TO BAG";
        }
    },
    mounted() {
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper
            const swiperThumbs = this.$refs.swiperThumbs.$swiper
            swiperTop.controller.control = swiperThumbs
            swiperThumbs.controller.control = swiperTop
        });
        this.getGoodsSkuDetail();
    },
    computed: {

    },
    methods: {
        colorClick(item, index) {
            this.ids = item.sku_id;
            this.colorIndex = index;
            this.productDetail.id = item.sku_id;
            this.productDetail.colorAtive = item.sku_id;
            this.productDetail.colorAtiveName = item.spec_value_name;
            this.getGoodsSkuDetail();
        },
        sizeClick(item, index) {
            this.ids = item.sku_id;
            this.sizeIndex = index;
            this.productDetail.id = item.sku_id;
            this.productDetail.sizeAtive = item.sku_id;
            this.productDetail.sizeAtiveName = item.spec_value_name;
            this.getGoodsSkuDetail();
        },
        prevClick() {
            if(this.navBannerAtive - 1 < 0){
                this.navBannerAtive = this.images.length - 1;
            }else{
                this.navBannerAtive = this.navBannerAtive - 1;
            }
            if(this.navBannerAtive > 5) {
                let width = -((this.navBannerAtive - 5) * 97);
                this.transformX = width;
            }else if(this.navBannerAtive <= 0) {
                this.transformX = 0;
            }
            this.$refs.navBanner.prev();
        },
        nextClick() {
            if(this.navBannerAtive + 1 >= this.images.length){
                this.navBannerAtive = 0
            }else{
                this.navBannerAtive = this.navBannerAtive + 1;
            }
            if(this.navBannerAtive > 5) {
                let width = -((this.navBannerAtive - 5) * 97);
                this.transformX = width;
            }else if(this.navBannerAtive <= 0) {
                this.transformX = 0;
            }
            this.$refs.navBanner.next();
        },
        navBannerChange(index) {
            this.navBannerAtive = index;
            if(this.navBannerAtive > 5) {
                let width = -((this.navBannerAtive - 5) * 97);
                this.transformX = width;
            }else if(this.navBannerAtive <= 0) {
                this.transformX = 0;
            }
            this.$refs.navBanner.setActiveItem(index);
        },
        toBagClick(){
            if(this.cart == true) {
                this.editToBag();
            }else{
                this.addToBag();
            }
        },
        editToBag() {
            editCartNum({ 
                cart_id: this.cartId,
                sku_id: this.toBag.sku_id,
                num: this.productDetail.num
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('Successfully modified');
                    this.$emit("close");
                }
            }).catch( err => {
                this.$message.error('Fail');
                this.$emit("close");
            })
        },
        addToBag() {
            let token = getToken();
            if(token == undefined){
                if(localStorage.getItem("cartProductsSoyego") == null || localStorage.getItem("cartProductsSoyego") == undefined){
                    let list = [];
                    list.push(this.productDetail);
                    localStorage.setItem('cartProductsSoyego', JSON.stringify(list));
                    this.$message.success('Added successfully');
                    this.$emit("close");
                }else{
                    let list = JSON.parse(localStorage.getItem("cartProductsSoyego"));
                    list.push(this.productDetail);
                    localStorage.setItem('cartProductsSoyego', JSON.stringify(list));
                    this.$message.success('Added successfully');
                    this.$emit("close");
                }
                // this.$router.push({ path: '/login' })
            }else{
                addToCart({
                    num: this.num,
                    sku_id: this.toBag.sku_id,
                    site_id: this.toBag.site_id,
                    cart_id: this.toBag.cart_id,
                }).then( res => {
                    if(res.code == 0 && res.data){
                        this.$message.success('Added successfully');
                        this.$emit("close");
                    }
                }).catch( err => {
                    this.$message.error('Add failed');
                    this.$emit("close");
                })
            }
        },
        fetchData() {
            this.loading = true;
            this.getGoodsSkuDetail();
            this.$forceUpdate();
        },
        getGoodsSkuDetail() {
            var that = this;
            this.productDetail.num = this.num;
            goodsSkuInfo({
                sku_id: this.ids,
                barterRate: this.countrySoyego + "D"
            }).then(res => {
                // console.log(res)
                if (res.code == 0 && res.data) {
                    let data = res.data;
                    this.toBag.sku_id = data.sku_id;
                    this.toBag.site_id = data.site_id;
                    this.toBag.cart_id = data.goods_id;

                    data.goods_attr_format = JSON.parse(data.goods_attr_format);
                    data.goods_image = data.goods_image.split(",");
                    data.goods_spec_format = JSON.parse(data.goods_spec_format);

                    this.images = data.goods_image;
                    // this.swiperOptionTop.loopedSlides = this.images.length;
                    // this.swiperOptionThumbs.loopedSlides = this.images.length;
                    this.productDetail.sku_id = data.sku_id;
                    this.productDetail.site_id = data.site_id;
                    this.productDetail.cart_id = data.goods_id;
                    this.productDetail.verify_state = data.verify_state;
                    this.productDetail.imgUrl = this.images[0];
                    this.productDetail.goods_name = data.goods_name;
                    // this.productDetail.num = data.num;
                    this.productDetail.sku_no = data.sku_no;
                    this.productDetail.market_price = data.market_price <= 0 ? data.price : data.market_price;
                    // this.productDetail.price = data.price;
                    this.productDetail.discount_price = data.discount_price;
                    let save = (data.market_price - data.discount_price).toFixed(2);
                    if (save < 0) {
                        this.productDetail.save = (data.price - data.discount_price).toFixed(2);
                    } else {
                        this.productDetail.save = (data.market_price - data.discount_price).toFixed(2);
                    }

                    data.goods_spec_format.forEach(item => {
                        if (item.spec_name == "Colour" || item.spec_name == "Color") {
                            this.productDetail.colorList = item.value;
                            this.productDetail.colorAtive = item.value[this.colorIndex].sku_id;
                            this.productDetail.colorAtiveName = item.value[this.colorIndex].spec_value_name;
                        }
                        if (item.spec_name == "Size") {
                            this.productDetail.sizeData = item.value;
                            this.productDetail.sizeAtive = item.value[this.sizeIndex].sku_id;
                            this.productDetail.sizeAtiveName = item.value[this.sizeIndex].spec_value_name;
                        }
                    });
                    // that.getSizeList(data.goods_id);

                    this.descriptionList = data.goods_attr_format;
                    this.loading = false;
                }

            }).catch(err => {
                this.loading = false
                // console.log(err)
            })
        },
        getSizeList(id) {
            this.productDetail.sizeData = [];
            goodsGetSizeGuied({ goods_id: id }).then(res => {
                if (res.code == 0) {
                    this.productDetail.sizeData = res.data;
                    this.productDetail.sizeAtive = this.productDetail.sizeData.cm[0].Size;
                }
            }).catch(err => {
                // console.log(res)
            })
        },
    }
}