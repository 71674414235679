<template>
    <div class="bagShop" v-loading="loading" element-loading-text="Loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="bagShop-nav">
            <div class="nav-top">
                <div class="nav-arrow">
                    <div class="prev" @click="prevClick()"><el-image :src="require('@/assets/images/index/ddps/＜.png')"></el-image></div>
                    <div class="next" @click="nextClick()"><el-image :src="require('@/assets/images/index/ddps/＞.png')"></el-image></div>
                </div> 
                <el-carousel :autoplay="false" arrow="never" style="width: 100%;" height="600px" indicator-position="none" ref="navBanner">
                    <el-carousel-item v-for="(item, index) in images" :key="index" style="width: 100%;height: 100%;">
                        <el-image :lazy-src="item" :src="item" fit="cover" style="width: 100%;height: 100%;"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="nav-bottom">
                <div class="bottom" :style="'transform:translateX('+transformX+'px)'">
                    <div v-for="(item, index) in images" :key="index" @click="navBannerChange(index)" :class="navBannerAtive == index ? 'border' : ''">
                        <div class="img">
                            <el-image :lazy-src="item" :src="item" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                <swiper-slide v-for="(item, index) in images" :key="index">
                    <el-image :lazy-src="item" :src="item" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                </swiper-slide>
            </swiper>
            <div class="swiper-button-next swiper-button-white" slot="button-next">
                <img src="@/assets/images/goods/next.png" />
            </div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev">
                <img src="@/assets/images/goods/prev.png" />
            </div>
            <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                <swiper-slide v-for="(item, index) in images" :key="index">
                    <el-image :lazy-src="item" :src="item" fit="contain" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                </swiper-slide>
            </swiper> -->
        </div>
        <div class="bagShop-text">
            <h5>{{ productDetail.goods_name }}</h5>
            <span>ID: {{ productDetail.cart_id }}</span>
            <!-- <div class="text-rate">
                <el-rate v-model="value" disabled show-score text-color="#ff9900" score-template="{value}"> </el-rate>
            </div> -->
            <div class="text-price">
                <div>
                    <span>{{ strCurXSoyego }}{{ productDetail.discount_price }}</span>
                    <s>{{ strCurXSoyego }}{{ productDetail.market_price }}</s>
                </div>
                <p>Save {{ strCurXSoyego }}{{ productDetail.save }}</p>
            </div>
            <div class="text-color" v-if="productDetail.colorList">
                <p>
                    Color:<span>{{ productDetail.colorAtiveName }}</span>
                </p>
                <div>
                    <div @click="colorClick(item, index)" v-for="(item, index) in productDetail.colorList" :key="index" :class="productDetail.colorAtive == item.sku_id ? 'outer-round round-ative' : 'outer-round'">
                        <div class="round">
                            <el-image v-if="item.image && item.image != ''" :src="item.image" fit="cover" style="cursor: pointer;width: 100%;height: 100%;border-radius: 50%;"></el-image>
                        </div>
                    </div>
                    <!-- <div
                        @click="
                            productDetail.colorAtive = item.sku_id;
                            productDetail.colorAtiveName = item.spec_value_name;
                        "
                        v-for="(item, index) in productDetail.colorList"
                        :key="index"
                        :class="productDetail.colorAtive == item.sku_id ? 'outer-round round-ative' : 'outer-round'"
                    >
                        <div class="round">
                            <el-image :src="item.image" fit="cover" style="cursor: pointer;width: 100%;height: 100%;border-radius: 50%;"></el-image>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="text-size" v-if="productDetail.sizeAtive">
                <p>Size: <span>{{productDetail.sizeAtiveName}}</span></p>
                <div class="size">
                    <span @click="sizeClick(item, index)" :class="productDetail.sizeAtiveName == item.spec_value_name ? 'ative' : ''" v-for="(item, index) in productDetail.sizeData" :key="index">{{item.spec_value_name}}</span>
                </div>
            </div>
            <div class="text-quantity">
                <p>Quantity</p>
                <el-input-number v-model="num" :min="1"></el-input-number>
            </div>
            <div class="btn">
                <button @click="toBagClick()">{{bagTitle}}</button>
            </div>
        </div>
    </div>
</template>

<style>
.bagShop .text-quantity .el-input-number {
    width: 7vw;
}
.bagShop .text-quantity .el-input-number__increase,
.bagShop .text-quantity .el-input-number__decrease {
    width: 28px;
    background: transparent;
    border-radius: 0;
}
.bagShop .text-quantity .el-input__inner {
    height: 40px;
    line-height: 40px;
    border-radius: 0;
}
.bagShop .text-quantity .el-input-number .el-input__inner {
    padding-left: 30px;
    padding-right: 30px;
}
.bagShop .text-quantity .el-input-number__increase:hover,
.bagShop .text-quantity .el-input-number__decrease:hover {
    color: #330000;
}
.bagShop .text-quantity .el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled),
.bagShop .text-quantity .el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled) {
    border-color: #330000;
}

.bagShop .text-quantity .el-checkbox__inner:hover {
    border-color: #330000;
}
.bagShop .text-quantity .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #330000;
    border-color: #330000;
}
</style>
<style lang="scss" scoped>
@import "../css/bagShop.scss";
</style>

<script>
import bagShop from "../js/bagShop.js"

export default {
    name: "bagShop",
    components: {},
    mixins: [bagShop]
}
</script>