import { cartList, editCartNum, deleteCart } from "@/api/goods/cart"
import { goodsSkuPage } from "@/api/goods/goods"
import { tree } from "@/api/shop"
import { getToken } from "@/utils/auth"

export default {
	name: "shoppingCart",
	components: {

	},
	data: () => {
		return {
			bagShop: false,
			list: [],
			total: 0.00,
			checkAll: false,
			checkedCities: [],
			isIndeterminate: true,
			checkedOptions: [],
			ids: 0,
			strCurXSoyego: localStorage.getItem("strCurXSoyego"),
			countrySoyego: localStorage.getItem('countrySoyego'),
			num: 1,
			checkoutList: "",
			checkoutChecked: [],
			likeList: [],
			loadLikeing: true,
			disabledShow: true,
			expiredProducts: 0,
			expiredProductsIds: "",
			loginShow: false,
			buyShow: false,
			cartShow: true,
			cartListLoading: true,
			cartId: 0,
			// 需要翻译的静态文字
            translateTextData: {
				SECURE_PAYMENT: 'SECURE PAYMENT',
				Continue_Shopping: 'Continue Shopping',
				BAG: 'BAG',
				Submit_Order: 'Submit Order',
				Payment: 'Payment',
				Complete: 'Complete',
				Select_All: 'Select All',
				Shipping_Address: 'Shipping Address',
				You_have_not_selected_the_delivery_address: 'You have not selected the delivery address',
				Item: 'Item',
				Price: 'Price',
				Quantity: 'Quantity',
				Color: 'Color',
				Size: 'Size',
				Logistics_Methods: 'Logistics Methods',
				Shipping_Info: 'Shipping Info',
				CHECKOUT: 'CHECKOUT',
				Order_placed_now_are_expected_to_arrived_before: 'Order placed now are expected to arrived before',
				day: 'day',
				Payment_Method: 'Payment Method',
				Promo_Code: 'Promo Code',
				Verify: 'Verify',
				Invalid_code: 'Invalid code',
				Order_Summary: 'Order Summary',
				Your_bag_is_empty: 'Your bag is empty',
				Retail_Price: 'Retail Price',
				SHOP_NOW: 'SHOP NOW',
				Logistics: 'Logistics',
				Coupon: 'Coupon',
				Total: 'Total',
				PAY_NOW: 'PAY NOW',
				You_may_also_like: 'You may also like',
				View_More: 'View More',
            },

			
			cartListData: [],	// 购物车数据
			str_cur_x: '$',		// 金钱符号
			isAll: false,  		// 是否全选
			goodsCartId: '', 	// 选中的商品
			checkGoodsNum: 0,   // 选中的商品数量
			goodsTotal: 0,      // 选中的商品总价
			editFlag: false,	// 修改购物车数量状态锁
		}
	},
	watch: {
		$route: "fetchData",
	},
	created() {
		this.getTranslateText()
	},
	mounted() {
		let token = getToken();
		if (token == undefined) {
			if (localStorage.getItem("cartProductsSoyego") != undefined && localStorage.getItem("cartProductsSoyego") != null) {
				this.getProductsList();
			}
		} else {
			// this.getCartList();
			this.getCartGoodsData();
		}
		this.getGoodsLike(false);
	},
	computed: {

	},
	methods: {
		// 翻译静态文本
        getTranslateText() {
            let strText = '';
            let i = 0;
            let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
            translateData.forEach((item,index) => {
                strText += item + '\n'
            })
            // 翻译文本
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    for(let key in this.translateTextData){
                        this.translateTextData[key] = resData[i];
                        i++;
                    }
                }
            })
        },
		loginClose() {
			this.$message.success("The products in shopping cart has been changed, please check!");
			this.loginShow = false;
			this.getCartList();
		},
		getProductsList() {
			let listCart = JSON.parse(localStorage.getItem("cartProductsSoyego"));
			if(listCart == undefined || listCart == null){
				this.list = [];
				this.cartListLoading = false;
				return;
			}
			var i = 0;
			listCart.forEach((item, index) => {
				i = index;
				this.$set(item, 'checked', index);
				this.$set(item, 'total', (parseFloat(item.discount_price) * item.num).toFixed(2));
				this.$set(item, 'sku_image', item.imgUrl);
				this.$set(item, 'size', item.sizeAtive);
				this.$set(item, 'color', item.colorAtiveName);
				if (item.verify_state == 1) {
					this.checkedOptions[i] = index;
				} else {
					this.expiredProductsIds = this.expiredProductsIds + item.id + ",";
					this.expiredProducts = this.expiredProducts + 1;
					i = i - 1;
				}
			});
			this.list = listCart;
			this.handleCheckAllChange(true);
			this.checkAll = true;
			this.disabledShow = false;
			this.cartListLoading = false;
		},
		deteleCart(cartId) {
			let token = getToken();
			if(token == undefined){
				let data = JSON.parse(localStorage.getItem("cartProductsSoyego"));
				var list = [];
				var i = 0;
				data.forEach(item =>{
					if(item.cart_id != cartId){
						list[i] = item;
					}
				})
				localStorage.removeItem("cartProductsSoyego");
				if(list.length > 0){
					localStorage.setItem('cartProductsSoyego', JSON.stringify(list));
				}
				this.getProductsList();
				this.$message.success("Deleted successfully");
			}else{
				deleteCart({ cart_id: cartId }).then(res => {
					if (res.code == 0) {
						this.expiredProducts = 0;
						this.expiredProductsIds = "";
						this.getCartList();
						this.$message.success("Deleted successfully");
					}
				})
			}			
		},
		// numberChange(index) {
		// 	console.log(this.list[index])
		// 	this.list[index].total = (this.list[index].discount_price * this.list[index].num).toFixed(2);
		// 	this.fetchData();
		// },
		numberChange(index, item) {
			var that = this;
			let token = getToken();
			if (token == undefined) {
				that.list[index].total = (this.list[index].discount_price * this.list[index].num).toFixed(2);
				var sum = 0;
				that.list.forEach(item => {
					if (item.verify_state == 1) {
						that.total = (parseFloat(sum) + parseFloat(item.total)).toFixed(2);
						sum = that.total;
					}
				})
			} else {
				editCartNum({ cart_id: item.cart_id, num: item.num }).then(res => {
					if (res.code == 0) {
						that.list[index].total = (this.list[index].discount_price * this.list[index].num).toFixed(2);
						var sum = 0;
						that.list.forEach(item => {
							if (item.verify_state == 1) {
								that.total = (parseFloat(sum) + parseFloat(item.total)).toFixed(2);
								sum = that.total;
							}
						})
					}
				})
			}
			this.fetchData();
		},
		detailsClick(id) {
			localStorage.setItem('productDetailsDropSoyego', false);
			const blank = this.$router.resolve({ path: '/productDetails', query: { sku_id: id } });
			window.open(blank.href, '_blank')
		},
		getGoodsLike(like) {
			this.loadLikeing = true;
			const params = {
				page: 1,
				page_size: 8,
				attr_rand: true,
				barterRate: this.countrySoyego
			};
			goodsSkuPage(params || {}).then(res => {
				if (res.code == 0 && res.data) {
					let data = res.data;
					let strText = '';
					data.list.forEach(item => {
						item.goods_image = item.goods_image.split(',');
						item.discount_price = Number(item.discount_price).toFixed(2)
						strText += item.goods_name + '\n';
					});
					if (like) {
						this.likeList = this.likeList.concat(data.list);
					} else {
						this.likeList = data.list;
					}
					// 翻译
                    this.$fanyi.translateData({
                        query: strText,
                        success: resData => {
                            // like true加载更多数据 以后可能会出现异步，有空优化; false 第一页数据
                            if(like){
                                data.list.forEach((item,index) => {
                                    data.list[index].goods_name = resData[index]
                                })
                                this.likeList = this.likeList.concat(data.list);
                            }else{
                                this.likeList.forEach((item,index) => {
                                    this.likeList[index].goods_name = resData[index];
                                })
                            }
                        }
                    })
					this.loadLikeing = false;
				}
			}).catch(err => {
				this.$message.error(err.message);
				this.loadLikeing = false;
			});
		},
		fetchData() {			
			this.$forceUpdate();
		},
		handleClose() {
			let token = getToken();
			if (token == undefined) {
				if (localStorage.getItem("cartProductsSoyego") != undefined && localStorage.getItem("cartProductsSoyego") != null) {
					this.getProductsList();
				}
			} else {
				this.getCartList();
			}
			this.bagShop = false;
		},
		handleClose1() {
			this.loginShow = false;
		},
		checkoutClick() {
			// if(this.disabledShow){
			// 	this.$message.waring("Please select the item you want to buy");
			// 	return;
			// }
			let token = getToken();
			if (token == undefined) {
				this.buyShow = true;
				this.loginShow = true;
			} else {
				var cartList = [];
				this.list.forEach((item, index) => {
					if (this.checkoutChecked.indexOf(item.checked) != -1) {
						this.checkoutList = this.checkoutList + item.cart_id + ",";
						if (cartList.indexOf(item.site_id) == -1) {
							cartList[index] = item.site_id;
						}
					}
				})
				
				localStorage.setItem('shoppProductCartSoyego', this.checkoutList);
				localStorage.setItem('shoppProductCartsiteIdSoyego', cartList);
				
				this.$router.push({ path: '/shoppPay', query: { data: "cart" } });
			}
		},
		bagShopClick(stuId, number, cartId) {
			this.ids = stuId;
			this.num = number;
			this.cartId = cartId;
			this.bagShop = true;
		},
		// 获取购物车商品数据
		getCartList() {
			let params = {
				barterRate: this.countrySoyego
			}
			cartList(params || {}).then(res => {
				if (res.code == 0 && res.data) {
					var i = 0;
					res.data.forEach((item, index) => {
						i = index;
						this.$set(item, 'checked', index);
						this.$set(item, 'total', (parseFloat(item.discount_price) * item.num).toFixed(2));
						item.sku_spec_format = JSON.parse(item.sku_spec_format);
						// console.log(item.sku_spec_format)
						if (item.sku_spec_format.length > 0) {
							item.sku_spec_format.forEach(item1 => {
								if (item1.spec_name == "Color" || item1.spec_name == "Colour") {
									this.$set(item, 'color', item1.spec_value_name);
									this.$set(item, 'colorImg', item1.image);
								}
								if (item1.spec_name == "Size") {
									this.$set(item, 'size', item1.spec_value_name);
								}
							})
						}
						if (item.verify_state == 1) {
							this.checkedOptions[i] = index;
						} else {
							this.expiredProductsIds = this.expiredProductsIds + item.cart_id + ",";
							this.expiredProducts = this.expiredProducts + 1;
							i = i - 1;
						}
					});
					this.list = res.data;
					// 翻译
					let strText = '';
					this.list.forEach((item,index) => {
						strText += item.goods_name + '\n';
					})
					this.$fanyi.translateData({
                        query: strText,
                        success: resData => {
                            // 渲染数据
                            this.list.forEach((item,index) => {
                                this.list[index].goods_name = resData[index]
                            })
                        }
                    })


					this.handleCheckAllChange(true);
					this.checkAll = true;
					this.disabledShow = false;
					this.cartListLoading = false;
				}
				// console.log(res)
			}).catch(err => {
				// console.log(err)
			})
		},
		handleCheckAllChange(val) {
			this.checkedCities = val ? this.checkedOptions : [];
			this.isIndeterminate = false;
			this.getCheckedAllTotal(val);
		},
		handleCheckedCitiesChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.list.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.length;

			this.getCheckedTotal(value);
		},
		getCheckedAllTotal(val) {
			if (val) {
				var that = this;
				var sum = 0;
				this.list.forEach(item => {
					if (item.verify_state == 1) {
						that.total = (parseFloat(sum) + parseFloat(item.total)).toFixed(2);
						sum = that.total;
					}
				})
				this.checkoutChecked = this.checkedOptions;
				this.disabledShow = false;
			} else {
				this.total = 0;
				this.checkoutChecked = [];
				this.disabledShow = true;
			}
		},
		getCheckedTotal(value) {
			this.checkoutChecked = value;
			var that = this;
			var sum = 0;
			if (value.length <= 0) {
				that.total = 0;
				this.disabledShow = true;
			} else {
				that.total = 0;
				this.list.forEach(item => {
					if (value.indexOf(item.checked) != -1 && item.verify_state == 1) {
						that.total = (parseFloat(sum) + parseFloat(item.total)).toFixed(2);
					}
					sum = that.total;
				})
				this.disabledShow = false;
			}
		},

		// 获取购物车商品数据
		getCartGoodsData() {
			var that = this;
			let params = {
				barterRate: this.countrySoyego
			}
			cartList(params || {}).then(res => {
				console.log('购物车数据',res);
				if(res.code == 0) {
					let data = res.data;
					data.forEach(item => {
						item.ischeck = false;
					});
					that.cartListData = data;
					that.calculationGoods();
					this.loadLikeing = false;
				}
			})
		},
		// 选中商品
		selectGoods(index) {
			var that = this;
			that.cartListData[index].ischeck = !that.cartListData[index].ischeck;
			that.calculationGoods();
		},
		// 全选
		selectAll() {
			var that = this;
			that.goodsCartId = '';
			that.goodsTotal = 0;
			that.isAll ? that.isAll = false : that.isAll = true;
			if(that.isAll) {
				that.cartListData.forEach(item => {
					item.ischeck = true;
					that.goodsCartId += item.cart_id + ',';
				});
			}else{
				that.cartListData.forEach(item => {
					item.ischeck = false;
				});
			}
			that.calculationGoods();
		},
		// 修改购物车商品数量
		modifyGoodsNumber(change,number,cart_id,sku_id,index) {
			var that = this;
			let token = getToken();
			let goodsNum = null;
			// 状态锁
			if(that.editFlag) return
			that.editFlag = true;

			if(change == 'reduce') {
				if(number == 1) return;
				goodsNum = number - 1;
			}else{
				goodsNum = number + 1;
			}
			let params = {
				token: token,
				num: goodsNum,
				cart_id: cart_id,
				sku_id: sku_id,
			}
			editCartNum(params || {}).then(res => {
				console.log(res);
				that.cartListData[index].num = goodsNum;
				that.calculationGoods();
				that.editFlag = false // 解除状态锁
			})
		},
		// 删除购物车商品
		deleteGodds(cart_id) {
			var that = this;
			let token = getToken();
			let params = {
				token: token,
				cart_id: cart_id,
			}
			deleteCart(params || {}).then(res => {
				if(res.code == 0) {
					console.log('删除成功',res);
					that.getCartGoodsData();
				}
			})
		},
		// 计算商品数量和价格
		calculationGoods() {
			var that = this;
			that.checkGoodsNum = 0;
			that.goodsTotal = 0;

			that.cartListData.forEach(item => {
				if(item.ischeck) {
					that.goodsTotal = that.goodsTotal + item.price * item.num;
				}else{
					// 取消全选
					that.isAll = false;
				}
			})
		},
		// 结算
		goodsCheckout() {
			var that = this;
			var cartList = [];
			// 计算选中的商品
			that.cartListData.forEach((item,index) => {
				if(item.ischeck) {
					this.checkoutList = this.checkoutList + item.cart_id + ",";
					if (cartList.indexOf(item.site_id) == -1) {
						cartList[index] = item.site_id;
					}
					console.log(cartList)
				}
			})
	
			localStorage.setItem('shoppProductCartSoyego', this.checkoutList);
			localStorage.setItem('shoppProductCartsiteIdSoyego', cartList);
			
			this.$router.push({ path: '/shoppPay', query: { data: "cart" } });
		},
	}
}
