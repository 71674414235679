<template>
    <div class="shoppingCart" >
        <div class="shoppingCart-header">
            <div class="header-container">
                <div class="shoppingCart-left">
                    <div class="left-icon">
                        <el-image :src="require('@/assets/images/shoppingCart/cart_icon.png')" fit="cover" />
                    </div>
                    <div class="left-title">shopping bag</div>
                </div>
                <div class="shoppingCart-right" @click="$router.push({ path: '/index?Stab=4' })">Continue Shopping</div>
            </div>
        </div>
        <div class="shoppingCart-selectAll" v-if="cartListData.length > 0 && cartListData">
            <div class="selectAll-container">
                <div class="selectAll-button" @click="selectAll()">
                    <div class="selectAll-icon">
                        <el-image v-if="isAll" :src="require('@/assets/images/shoppingCart/check.png')" fit="cover" />
                        <el-image v-else :src="require('@/assets/images/shoppingCart/check_un.png')" fit="cover" />
                    </div>
                    <div class="selectAll-text">Select All</div>
                </div>
            </div>
        </div>
        <div class="shoppingCart-goodsList">
            <div class="goodsList-container">
                <div class="goodsList-item" v-for="(item,index) in cartListData">
                    <div class="item-left" @click="selectGoods(index)">
                        <div class="item-checkButton">
                            <el-image v-if="item.ischeck" :src="require('@/assets/images/shoppingCart/check.png')" fit="cover" />
                            <el-image v-else :src="require('@/assets/images/shoppingCart/check_un.png')" fit="cover" />
                        </div>
                        <div class="item-cover">
                            <el-image :src="$img(item.sku_image)" fit="cover" />
                        </div>
                    </div>
                    <div class="item-goodsInfo">
                        <div class="item-goodsName">{{item.goods_name}}</div>
                        <!-- <div class="item-colorSize">尺寸: S / 颜色:</div> -->
                    </div>
                    <div class="item-price">{{item.str_cur_x}} {{item.price.toFixed(2)}}</div>
                    <div class="item-goodsNum">
                        <div class="goodsNum-content">
                            <div class="goodsNum-reduce" @click="modifyGoodsNumber('reduce',item.num,item.cart_id,item.sku_id,index)">
                                <el-image :src="require('@/assets/images/shoppingCart/reduce_icon.png')" fit="cover" />
                            </div>
                            <div class="goodsNum-number">{{item.num}}</div>
                            <div class="goodsNum-add" @click="modifyGoodsNumber('add',item.num,item.cart_id,item.sku_id,index)">
                                <el-image :src="require('@/assets/images/shoppingCart/add_icon.png')" fit="cover" />
                            </div>
                        </div>
                    </div>
                    <div class="item-delete">
                        <div class="delete-button" @click="deleteGodds(item.cart_id)">
                            <el-image :src="require('@/assets/images/shoppingCart/delete.png')" fit="cover" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shoppingCart-footer" v-if="cartListData.length > 0 && cartListData">
            <div class="footer-container">
                <div class="footer-main">
                    <div class="main-summary">
                        <div class="summary-title">Order Summary</div>
                        <div class="summary-price">{{strCurXSoyego}} {{goodsTotal.toFixed(2)}}</div>
                    </div>
                    <div class="main-total">
                        <div class="total-title">Total</div>
                        <div class="total-price">{{strCurXSoyego}} {{goodsTotal.toFixed(2)}}</div>
                    </div>
                    <div class="main-checkout" @click="goodsCheckout()">
                        <span class="checkout-button">CHECKOUT</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="shoppingCart-emptyTips" v-if="cartListData.length == 0">
            <div class="emptyTips-container">
                <div class="emptyTips-main">
                    <div class="main-image">
                        <el-image :src="require('@/assets/images/shoppingCart/cart_emptyTips.png')" fit="cover" />
                    </div>
                    <div class="main-text">No items have been added to your list</div>
                </div>
            </div>
        </div>
        <div class="shoppingCart-recommen" v-loading="loadLikeing">
            <div class="recommen-container">
                <div class="recommen-header">
                    <div class="header-title">
                        <span>You may also like</span>
                    </div>
                </div>
                <div class="recommen-goodsList">
                    <div class="goodsList-item" v-for="(item,index) in likeList">
                        <div class="item-cover" @click="detailsClick(item.sku_id)">
                            <el-image :src="$img(item.sku_image)" fit="cover" />
                        </div>
                        <div class="item-goodsNmae">{{item.sku_name}}</div>
                        <div class="item-price">{{item.str_cur_x}}{{item.price}}</div>
                    </div>
                </div>
                <div class="recommen-more">
                    <div class="more-button" @click="getGoodsLike(true)">
                        <span>View More</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import shoppingCart from "./js/shoppingCart.js"
import CommonFooter from "@/layout/components/commonFooter.vue"
import BagShop from "./components/bagShop.vue"
import LoginComponent from "@/views/login/login.vue"

export default {
    name: "shoppingCart",
    components: {
        CommonFooter,
        BagShop,
        LoginComponent
    },
    mixins: [shoppingCart]
}
</script>

<style lang="scss" scoped>
// @import "./css/shoppingCart.scss";
    .shoppingCart{
        width: 100%;
        .shoppingCart-header{
            width: 100%;
            display: flex;
            justify-content: center;
            .header-container{
                width: 75vw;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 50px 0px;
                .shoppingCart-left{
                    display: flex;
                    .left-icon{
                        width: 36px;
                        height: 36px;
                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .left-title{
                        color: rgba(51, 51, 51, 1);
                        font-size: 34px;
                        font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                        line-height: 34px;
                        margin-left: 12px;
                    }
                }
                .shoppingCart-right{
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    line-height: 14px;
                    font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                    padding-bottom: 2px;
                    border-bottom: 1px solid #330000;
                    cursor: pointer;
                }
            }
        }
        .shoppingCart-selectAll{
            width: 100%;
            display: flex;
            justify-content: center;
            .selectAll-container{
                width: 75vw;
                display: flex;
                padding: 30px 0px;
                border-bottom: 1px solid #C7C7C7;
                .selectAll-button{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .selectAll-icon{
                        width: 20px;
                        height: 20px;
                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .selectAll-text{
                        color: rgba(51, 51, 51, 1);
                        font-size: 20px;
                        font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                        line-height: 20px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .shoppingCart-goodsList{
            width: 100%;
            display: flex;
            justify-content: center;
            .goodsList-container{
                width: 68.75vw;
                display: flex;
                flex-direction: column;
                .goodsList-item{
                    display: flex;
                    padding: 20px 0px;
                    border-bottom: 1px solid #EFEFEF;
                    .item-left{
                        display: flex;
                        align-items: center;
                        
                        .item-checkButton{
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .item-cover{
                            width: 150px;
                            height: 150px;
                            background-color: #C7C7C7;
                            margin-left: 30px;
                            cursor: pointer;
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .item-goodsInfo{
                        max-width: 16.23vw;
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        margin-left: 20px;
                        .item-goodsName{
                            color: rgba(51, 51, 51, 1);
                            font-size: 16px;
                            line-height: 20px;
                            font-family: Poppins-Regular;
                            /* 1.溢出隐藏 */
                            overflow: hidden;
                            /* 2.用省略号来代替超出文本 */
                            text-overflow: ellipsis;
                            /* 3.设置盒子属性为-webkit-box  必须的 */
                            display: -webkit-box;
                            /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
                            -webkit-line-clamp: 2;
                            /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
                            word-break: break-all;
                            /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
                            -webkit-box-orient: vertical;
                        }
                        .item-colorSize{
                            color: rgba(51, 51, 51, 1);
                            font-size: 16px;
                            font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                            line-height: 16px;
                            margin-top: 12px;
                            border: 1px solid #EFEFEF;
                            padding: 12px 10px;
                        }
                    }
                    .item-price{
                        color: rgba(51, 0, 0, 1);
                        font-size: 16px;
                        line-height: 16px;
                        font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                        flex-grow: 1;
                        text-align: center;
                    }
                    .item-goodsNum{
                        // display: flex;
                        // align-items: center;
                        .goodsNum-content{
                            display: flex;
                            align-items: center;
                            .goodsNum-reduce{
                                width: 6px;
                                height: 2px;
                                position: relative;
                                cursor: pointer;
                                .el-image{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                }
                            }
                            .goodsNum-number{
                                font-size: 16px;
                                line-height: 16px;
                                color: #330000;
                                font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                                flex-grow: 1;
                                text-align: center;
                                padding: 0px 12px;
                            }
                            .goodsNum-add{
                                width: 10px;
                                height: 10px;
                                position: relative;
                                cursor: pointer;
                                .el-image{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                }
                            }
                        }
                        
                    }
                    .item-delete{
                        display: flex;
                        flex-direction: row-reverse;
                        flex-grow: 1;
                        .delete-button{
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        
                    }
                }
            }
        }
        .shoppingCart-footer{
            width: 100%;
            display: flex;
            justify-content: center;
            .footer-container{
                width: 68.75vw;
                display: flex;
                flex-direction: row-reverse;
                padding: 42px 0px;
                .footer-main{
                    width: 24.70vw;
                    display: flex;
                    flex-direction: column;
                    .main-summary{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .summary-title{
                            color: rgba(132, 126, 126, 1);
                            font-size: 14px;
                            line-height: 14px;
                            font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                        }
                        .summary-price{
                            color: rgba(132, 126, 126, 1);
                            font-size: 14px;
                            font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                            line-height: 14px;
                        }
                    }
                    .main-total{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 20px;
                        .total-title{
                            color: rgba(51, 51, 51, 1);
                            font-size: 20px;
                            font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                            line-height: 20px;
                        }
                        .total-price{
                            color: rgba(51, 0, 0, 1);
                            font-size: 20px;
                            font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                            line-height: 20px;
                        }
                    }
                    .main-checkout{
                        background-color: rgba(51, 0, 0, 1);
                        width: 24.70vw;
                        height: 50px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;
                        cursor: pointer;
                        span{
                            color: rgba(255, 255, 255, 1);
                            font-size: 20px;
                            line-height: 20px;
                            font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                        }
                    }
                }
            }
        }
        .shoppingCart-emptyTips{
            width: 100%;
            display: flex;
            justify-content: center;
            .emptyTips-container{
                width: 88.54vw;
                padding: 100px 0px;
                display: flex;
                justify-content: center;
                .emptyTips-main{
                    min-width: 300px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .main-image{
                        width: 88px;
                        height: 73px;
                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .main-text{
                        color: rgba(132, 126, 126, 1);
                        font-size: 12px;
                        line-height: 12px;
                        font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
                        margin-top: 20px;
                    }
                }
            }
        }
        .shoppingCart-recommen{
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 80px 0px;
            .recommen-container{
                width: 88.54vw;
                display: flex;
                flex-direction: column;
                .recommen-header{
                    display: flex;
                    justify-content: center;
                    .header-title{
                        width: 22.82vw;
                        height: calc(22.82vw * 84 / 442);
                        border: 1px solid #000000;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        span{
                            color: rgba(51, 0, 0, 1);
                            font-size: 32px;
                            line-height: 32px;
                            font-weight: 300;
                            font-family: Poppins-Light;
                            zoom: .8;
                        }
                    }
                }
                .recommen-goodsList{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 20px;
                    .goodsList-item{
                        width: 21.35vw;
                        margin-top: 40px;
                        .item-cover{
                            width: 21.35vw;
                            height: calc(21.35vw * 541 / 410);
                            background-color: #330000;
                            cursor: pointer;
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .item-goodsNmae{
                            height: 42px;
                            margin-top: 20px;
                            text-align: center;
                            color: rgba(51, 51, 51, 1);
                            font-size: 16px;
                            line-height: 20px;
                            font-family: Poppins-Regular;
                            /* 1.溢出隐藏 */
                            overflow: hidden;
                            /* 2.用省略号来代替超出文本 */
                            text-overflow: ellipsis;
                            /* 3.设置盒子属性为-webkit-box  必须的 */
                            display: -webkit-box;
                            /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
                            -webkit-line-clamp: 2;
                            /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
                            word-break: break-all;
                            /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
                            -webkit-box-orient: vertical;
                        }
                        .item-price{
                            color: rgba(51, 0, 0, 1);
                            font-size: 16px;
                            line-height: 16px;
                            font-family: Poppins-Medium;
                            margin-top: 8px;
                            text-align: center;
                        }
                    }
                }
                .recommen-more{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top:40px;
                    .more-button{
                        width:22.30vw;
                        height:calc(22.30vw * 64 / 428);
                        background-color: #330000;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        span{
                            color:#fff;
                            font-size:18px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
@media (max-width: 860px) {
    .shoppingCart{
        .shoppingCart-recommen{
            padding: 2vh 0px;
            .recommen-container{
                .recommen-header{
                        display: flex;
                        justify-content: center;
                        .header-title{
                            width: 23.02vw;
                            height: calc(23.02vw * 84 / 442);
                            border: 1px solid #000000;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            span{
                                color: rgba(51, 0, 0, 1);
                                font-size: 30px;
                                line-height: 30px;
                                font-weight: 300;
                                font-family: Poppins-Light;
                                zoom: .7;
                            }
                        }
                    }
                .recommen-goodsList{
                    .goodsList-item{
                        margin-top: 30px;
                        .item-goodsName{
                            margin-top: 16px;
                            font-size: 14px;
                        }
                    }
                }
                .recommen-more{
                    margin-top: 26px;
                    .more-button{
                        span{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    
}
</style>